const _removeExtraTags = (element, tagTypes) => {
  tagTypes.forEach((tagType) => {
    const tags = Array.from(element.querySelectorAll(tagType));
    tags.slice(1).forEach((elem) => elem.remove());
  });
};

const _createDivElementWithHtml = (htmlString, document) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.innerHTML = htmlString;
  return wrapperElement;
};

const _getElements = (element, document) => {
  const wrapperElement = _createDivElementWithHtml(element, document);
  return Array.from(wrapperElement.children);
};

const _getFirstLeafElement = (root) => {
  let node = root;
  while (node?.children.length) {
    node = node.children[0];
  }
  return node;
};

const replaceQuillContent = (element, theme, document = window.document) => {
  const divElements = _getElements(element, document);

  return divElements
    .map((div) => {
      const originalText = div.textContent;

      const rootElement = _createDivElementWithHtml(theme, document);

      const firstLeafElement = _getFirstLeafElement(rootElement);
      firstLeafElement.textContent = originalText;

      _removeExtraTags(rootElement, ['div', 'span']);

      return rootElement.innerHTML;
    })
    .join('');
};

module.exports = {
  replaceQuillContent,
};
